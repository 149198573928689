/* eslint-disable */
import React, { useEffect } from 'react';
import { useConfig } from './ConfigProvider';

/* Hotjar Tracking Code for www.mybuilder.com */
const initHotjar = () => {
  const h = window as any;
  const o = document;
  const t = 'https://static.hotjar.com/c/hotjar-';
  const j = '.js?sv=';
  const a = o.getElementsByTagName('head')[0];
  const r = o.createElement('script');

  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: 1496749, hjsv: 6 };
  r.async = true;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};

const HotjarProvider = ({ children }: { children: React.ReactNode }) => {
  const { isProd } = useConfig();

  useEffect(() => {
    if (isProd) {
      initHotjar();
    }
  }, [isProd]);

  return <>{children}</>;
};

export default HotjarProvider;
