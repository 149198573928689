import { useCallback, useEffect, useState } from 'react';
import { IJourneyEventMetaData, IJourneyState } from '../gbg-sdk/idscan-jcs';

const localStorageKey = 'MB_ID_SCAN_PREVIOUS_ERROR';

// This hook makes sure the asynchronous error is still caught by the ErrorBoundary component.
// This then handles displaying an error message to the user.
const useHandleError = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>();
  const [hasPreviousError, setHasPreviousError] = useState(() => {
    const item = localStorage.getItem(localStorageKey);
    return item && item === 'true';
  });

  const setPreviousError = useCallback(() => {
    localStorage.setItem(localStorageKey, 'true');
    setHasPreviousError(true);
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const handleError = useCallback(
    (meta: IJourneyEventMetaData, state: IJourneyState) => {
      const redactedState = {
        ...state,
        journey: {
          ...state.journey,
          person: 'sensitive',
          documents: 'sensitive',
        },
      };
      // In order to get any properties of the inner exception that aren't name, message or stack we have to do this.
      // This will give us extra information to help debugging.
      const { response, ...exception } = meta?.exception || {};
      const innerException = response
        ? Object.keys(Object.getPrototypeOf(response)).reduce(
            (x, key) => ({ ...x, [`innerException.${key}`]: response[key] }),
            {}
          )
        : {};
      setError({
        meta: {
          ...meta,
          exception: {
            ...exception,
            ...innerException,
          },
        },
        state: redactedState,
      });

      setPreviousError();
    },
    [setPreviousError]
  );

  return { handleError, hasPreviousError };
};

export default useHandleError;
