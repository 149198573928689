import React from 'react';
import { renderToString } from 'react-dom/server';

import {
  TemplateDictionary,
  IJourneyState,
  JourneyEvent,
  IJourneyEventMetaData,
  IFunctionTemplateConfiguration,
} from '../gbg-sdk/idscan-jcs';

import SmartCaptureTemplate, { smartCaptureJs } from './SmartCapture';
import FileSystemTemplate, { filesystemJs } from './FileSystem';
import LivenessTemplate, { livenessJs } from './Liveness';
import InitializingTemplate from './Initializing';
import ViewTemplate, { viewJs } from './View';
import GatewayTemplate from './Gateway';
import ResultTemplate from './Result';
import LoginTemplate from './Login';

import { ConfigProvider } from '../providers/ConfigProvider';
import IdCheckContextProvider from '../providers/IdCheckContextProvider';

const renderWithConfig = (element: React.ReactElement) => () =>
  renderToString(
    <ConfigProvider>
      <IdCheckContextProvider>{element}</IdCheckContextProvider>
    </ConfigProvider>
  );

const templates: TemplateDictionary = {
  initializing: {
    type: 'function',
    processor: 'none',
    provider: renderWithConfig(<InitializingTemplate />),
  } as IFunctionTemplateConfiguration,
  login: {
    type: 'function',
    processor: 'none',
    provider: renderWithConfig(<LoginTemplate />),
  } as IFunctionTemplateConfiguration,
  filesystem: {
    type: 'function',
    processor: 'none',
    provider: renderWithConfig(<FileSystemTemplate />),
  } as IFunctionTemplateConfiguration,
  gateway: {
    type: 'function',
    processor: 'none',
    provider: renderWithConfig(<GatewayTemplate />),
  } as IFunctionTemplateConfiguration,
  // smartCapture: {
  //   type: 'function',
  //   processor: 'none',
  //   provider: renderWithConfig(<SmartCaptureTemplate />),
  // } as IFunctionTemplateConfiguration,
  view: {
    type: 'function',
    processor: 'none',
    provider: renderWithConfig(<ViewTemplate />),
  } as IFunctionTemplateConfiguration,
  liveness: {
    type: 'function',
    processor: 'none',
    provider: renderWithConfig(<LivenessTemplate />),
  } as IFunctionTemplateConfiguration,
  result: {
    type: 'function',
    // This template relies heavily on mustache to inject the data
    processor: 'mustache',
    provider: renderWithConfig(<ResultTemplate />),
  } as IFunctionTemplateConfiguration,
};

export const javascriptForProvider = (
  event: JourneyEvent,
  meta: IJourneyEventMetaData,
  state: IJourneyState
) => {
  smartCaptureJs(event, meta, state);
  filesystemJs(event, meta, state);
  livenessJs(event, meta, state);
  viewJs(event, meta, state);
};

export default templates;
