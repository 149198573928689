import React from 'react';

import { isMobile } from 'react-device-detect';

import {
  FlowState,
  IJourneyEventMetaData,
  IJourneyState,
  InputProvider,
  JourneyEvent,
} from '../../gbg-sdk/idscan-jcs';

import Processing from '../../assets/icons/processing.svg';
import Passport from '../../assets/icons/passport.png';
import IdBack from '../../assets/icons/id-back.svg';
import Selfie from '../../assets/icons/selfie.svg';

import Header from '../shared/Header';

const setActionText = (
  header: string,
  description: string | null,
  buttonText: string
) => {
  const headerContainer = document.querySelector('#upload-header');
  const descriptionContainer = document.querySelector('#upload-description');
  const addButton = document.querySelector('#add-button');
  if (headerContainer) {
    headerContainer.innerHTML = header;
  }
  if (descriptionContainer) {
    if (description === null) {
      descriptionContainer.classList.add('hidden');
    } else {
      descriptionContainer.innerHTML = description;
    }
  }
  if (addButton) {
    addButton.innerHTML = buttonText;
  }
};

const defaultErrorMessage =
  "Sorry that photo wasn't quite right, please try another";

const showErrorMessage = (message?: string) => {
  const container = document.querySelector('#try-again');
  if (container) {
    container.innerHTML = message || defaultErrorMessage;
    container.classList.remove('hidden');
  }
};

const showSmartCaptureToggle = () => {
  // This is not needed until we turn on smart capture functionality
  // const switchCaptureMethod = document.querySelector('#switch-id-capture');
  // switchCaptureMethod?.classList.remove('text-white');
  // switchCaptureMethod?.classList.remove('hidden');
};

type IconId = 'id-icon' | 'id-back-icon' | 'face-icon';
const icons: IconId[] = ['id-icon', 'id-back-icon', 'face-icon'];

const showIcon = (icon: IconId) => {
  icons.forEach((iconId) => {
    const element = document.querySelector(`#${iconId}`);
    if (iconId === icon) {
      element?.classList.remove('hidden');
      return;
    }
    element?.classList.add('hidden');
  });
};

const setCaptureMode = (captureMode: 'environment' | 'user') => {
  const input = document.querySelector('#jcs__file__input');
  if (isMobile && input) {
    input.setAttribute('accept', 'image/*');
    input.setAttribute('capture', captureMode);
  }
};

export const filesystemJs = (() => {
  let hasTriedUpload = false;

  return (
    event: JourneyEvent,
    meta: IJourneyEventMetaData,
    state: IJourneyState
  ) => {
    switch (event) {
      case JourneyEvent.DISPLAY_PROVIDER:
        if (meta.provider === 'filesystem') {
          // We render the same template for every type of upload, so we need to differentiate the text here
          switch (state.action) {
            case FlowState.IDENTITY_FRONT:
              setCaptureMode('environment');
              setActionText(
                'Photo ID required',
                'We accept passports, driving licences and ID cards from a wide variety of countries.',
                'Add photo ID'
              );
              showSmartCaptureToggle();
              if (hasTriedUpload && state.actionAttempt > 1) {
                showErrorMessage(
                  "We've detected a problem with that photo of your ID document. Please try another."
                );
              }
              break;
            case FlowState.IDENTITY_BACK:
              setCaptureMode('environment');
              setActionText(
                'Got it! Now we need the other side of your photo ID',
                null,
                'Add back of photo ID'
              );
              showSmartCaptureToggle();
              showIcon('id-back-icon');
              if (hasTriedUpload && state.actionAttempt > 1) {
                showErrorMessage(
                  "We've detected a problem with that photo of your ID document. Please try another."
                );
              }
              break;
            case FlowState.SELFIE:
              setCaptureMode('user');
              setActionText(
                'Selfie time!',
                'We need photos of you to help verify your ID. Make sure you are looking straight into the camera, your face is clearly visible, and that you are smiling.',
                'Add selfie'
              );
              showIcon('face-icon');
              if (hasTriedUpload && state.actionAttempt > 1) {
                showErrorMessage(
                  "We've detected a problem with that selfie. Please try another selfie."
                );
              }
              break;
            default:
              if (state.actionAttempt > 1) {
                showErrorMessage();
              }
              break;
          }

          hasTriedUpload = false;
        }
        break;
      case JourneyEvent.TRANSFER_STARTED:
        if (
          state.inputProvider === InputProvider.FILESYSTEM &&
          meta.name === 'upload'
        ) {
          document.querySelector('#success')?.classList.remove('hidden');
          hasTriedUpload = true;
        }
        break;
      default:
        break;
    }
  };
})();

const FileSystemTemplate = () => (
  <div className="h-full lg:h-auto lg:bg-transparent">
    <Header title="ID Check" />
    <div className="px-6 lg:px-0">
      <div className="py-6 lg:py-4 lg:px-6 lg:bg-white lg:mt-2 lg:rounded">
        <div
          id="upload-header"
          className="inline-block text-mb-heading font-bold text-xl lg:text-lg"
        >
          Upload a photo
        </div>
        <div
          id="upload-description"
          className="mt-2 text-sm lg:text-base whitespace-pre-wrap"
        >
          Please upload a photo.
        </div>
      </div>
      <div className="pb-36 lg:p-4 lg:pb-2 lg:bg-white lg:rounded-t lg:mt-2">
        <label
          data-jcs-element="file__drop__box"
          id="file-drop"
          className="relative flex flex-col justify-center items-center py-8 min-h-file-upload max-h-file-upload cursor-pointer bg-mb-blue-lighter border-dashed border-mb-gray-light border-2 rounded text-center text-mb-gray-mid overflow-hidden"
          htmlFor="jcs__file__input"
        >
          <div
            id="success"
            className="absolute left-0 right-0 top-0 bottom-0 h-full z-50 hidden flex flex-col justify-center items-center bg-mb-gray-bg-trans bg-blur lg:rounded"
          >
            <img src={Processing} alt="" />
            <span className="loading text-center text-white font-bold text-xl mt-2">
              Processing
            </span>
          </div>
          <input
            data-jcs-element="file__input"
            className="hidden"
            id="jcs__file__input"
            type="file"
          />
          <img id="id-icon" src={Passport} alt="" />
          <img id="id-back-icon" className="hidden" src={IdBack} alt="" />
          <img id="face-icon" className="hidden" src={Selfie} alt="" />
          <div>
            <span
              id="add-button"
              data-jcs-element="file__drop__label"
              className="block bg-mb-green rounded mt-4 mb-2 p-2 text-white font-display"
            >
              Add photo
            </span>
            <span className="hidden lg:block text-xs">.png, .jpg, .jpeg</span>
          </div>
        </label>
        <div
          id="try-again"
          className="hidden text-center text-mb-red font-bold pt-2 max-w-70% lg:max-w-full mx-auto"
        >
          {defaultErrorMessage}
        </div>
      </div>
    </div>
  </div>
);

export default FileSystemTemplate;
