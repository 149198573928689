/* eslint-disable import/prefer-default-export */
import { TrackJS } from 'trackjs';

const trackJsToken = 'a47c4288ca164c9098650382b58d4b23';

const { REACT_APP_STAGE: stage } = process.env;

export const installTrackJsIfNeeded = () => {
  if (!TrackJS.isInstalled()) {
    const queryParams = new URLSearchParams(window.location.search);
    const idCheckToken = queryParams.get('idCheckToken');
    TrackJS.install({
      token: trackJsToken,
      application:
        stage === 'prod' ? 'live-id-check-flow' : 'dev-id-check-flow',
      userId: idCheckToken ?? '',
    });
  }
};
