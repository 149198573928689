import React from 'react';

// We never want to show the login page to the user so we just show a loading page as this
// gets shown when they lose authentication and we're getting a new token
const LoginTemplate = () => (
  <div className="text-center pt-4 lg:pt-0 h-screen">
    <h2 className="text-lg text-mb-blue font-bold loading">
      Your session timed out, attempting to restart
    </h2>
  </div>
);

export default LoginTemplate;
