import React from 'react';
import MbLogoWhite from '../assets/logos/mb-logo-white.svg';

const Loading = () => (
  <div className="text-center bg-mb-blue lg:bg-transparent pt-20 h-screen">
    <img
      src={MbLogoWhite}
      alt="MyBuilder"
      className="inline-block max-w-mb-logo ml-4 pb-4 lg:hidden"
    />
    <h2 className="text-2xl text-white lg:text-mb-blue font-bold loading">
      Loading
    </h2>
  </div>
);

export default Loading;
