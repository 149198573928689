import React from 'react';
import {
  FlowState,
  IJourneyEventMetaData,
  IJourneyState,
  InputProvider,
  JourneyEvent,
} from '../../gbg-sdk/idscan-jcs';

import Header from '../shared/Header';
import Processing from '../../assets/icons/processing.svg';
import IdIcon from '../../assets/icons/passport.png';

import { CameraFeedback, getCommentary } from '../templateUtils';

const initialInstruction = 'Align your ID inside the box.';

const setOverlayVisibility = (visible: boolean) => {
  const overlay = document.querySelector('#overlay');
  const switchContainer = document.querySelector('#switch-id-capture');
  const instructionText = document.querySelector('#capture-instructions');
  if (visible) {
    switchContainer?.classList.remove('hidden');
    instructionText?.classList.add('hidden');
    overlay?.classList.remove('hidden');
    return;
  }
  instructionText?.classList.remove('hidden');
  switchContainer?.classList.add('hidden');
  overlay?.classList.add('hidden');
};

const setInstructionsText = (feedback: CameraFeedback) => {
  const container = document.querySelector('#capture-instructions');
  document.querySelector('#success')?.classList.add('hidden');
  if (container && feedback.failed) {
    container.innerHTML = initialInstruction;
    return;
  }
  const instructions = getCommentary(feedback);
  if (container && instructions) {
    container.classList.remove('hidden');
    container.innerHTML = instructions;
  }
};

const handleCameraFeedback = (feedback: CameraFeedback) => {
  setInstructionsText(feedback);
  const failMessage = document.querySelector('#try-again');
  if (feedback.failed) {
    setOverlayVisibility(true);
    failMessage?.classList.remove('hidden');
    return;
  }
  setOverlayVisibility(false);
  failMessage?.classList.add('hidden');
};

const setProcessing = () => {
  document.querySelector('#capture-instructions')?.classList.add('hidden');
  document.querySelector('#success')?.classList.remove('hidden');
};

const setupProxyButton = () => {
  const proxyButton = document.querySelector(
    '#proxy-scan-button'
  ) as HTMLInputElement;
  const realButton = document.querySelector('#real-scan-button');

  const startDelayedScanning = () => {
    setOverlayVisibility(false);
    setTimeout(() => {
      realButton?.dispatchEvent(new Event('click'));
    }, 2000);
  };

  proxyButton?.addEventListener('click', startDelayedScanning);
  proxyButton?.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      startDelayedScanning();
    }
  });
};

const removeLoadingScreen = () => {
  document.querySelector('#smart-capture-loading')?.classList.add('hidden');
  document
    .querySelector('#smart-capture-container')
    ?.classList.remove('hidden');
  document.querySelector('#switch-id-capture')?.classList.remove('hidden');
};

export const smartCaptureJs = (() => {
  let hasTriedUpload = false;

  return (
    event: JourneyEvent,
    meta: IJourneyEventMetaData,
    state: IJourneyState
  ) => {
    switch (event) {
      case JourneyEvent.DISPLAY_PROVIDER:
        if (meta.provider !== 'smartCapture') {
          break;
        }
        setupProxyButton();
        removeLoadingScreen();
        if (hasTriedUpload && state.actionAttempt > 1) {
          document.querySelector('#try-again')?.classList.remove('hidden');
          hasTriedUpload = false;
        }
        if (state.action === FlowState.IDENTITY_BACK) {
          const heading = document.querySelector('#scanner-heading');
          if (heading) {
            heading.classList.remove('lg:hidden');
            heading.innerHTML = 'Now scan the back of your ID';
          }
        }
        break;
      case JourneyEvent.CAMERA_START:
      case JourneyEvent.CAMERA_STOP:
      case JourneyEvent.CAMERA_FEEDBACK:
        if (
          [
            FlowState.IDENTITY_FRONT,
            FlowState.IDENTITY_BACK,
            FlowState.SELFIE,
          ].includes(state.action)
        ) {
          handleCameraFeedback(meta as CameraFeedback);
        }
        break;

      case JourneyEvent.TRANSFER_STARTED:
        if (
          state.inputProvider === InputProvider.SMART_CAPTURE &&
          meta.name === 'upload'
        ) {
          setProcessing();
          hasTriedUpload = true;
        }
        break;
      default:
        break;
    }
  };
})();

const SmartCaptureTemplate = () => (
  <>
    <div
      id="smart-capture-loading"
      className="text-center pt-4 lg:pt-0 h-screen"
    >
      <h2 className="text-lg text-mb-blue font-bold loading">Loading</h2>
      <p>Loading the camera</p>
    </div>
    <div id="smart-capture-container" className="hidden">
      <Header title="Scan your ID document" />
      {/* Not sure how to display this, a select feels so weird */}
      <select
        data-jcs-element="camera__select"
        className="camera-choices lg:rounded hidden"
      />
      <div className="relative bg-mb-blue-bg lg:bg-transparent lg:mt-2 bg-mb-gray-bg-trans">
        <div
          id="overlay"
          className="absolute lg:static lg:block h-full w-full lg:h-auto z-40 lg:z-auto bg-mb-gray-bg-trans bg-blur lg:bg-white lg:mt-2 pb-16 pt-6 lg:py-4 lg:px-6 lg:rounded text-center lg:text-left flex flex-col justify-center items-center lg:justify-start lg:items-start"
        >
          <img
            src={IdIcon}
            alt=""
            className="mb-4 w-icon-mobile lg:hidden transform -rotate-90 sm:rotate-0"
          />
          <div
            id="scanner-heading"
            className="inline-block lg:hidden text-white lg:text-mb-text text-lg font-bold mb-2 max-w-70% lg:max-w-full mx-auto"
          >
            Prepare to scan your ID
          </div>
          <div className="text-white lg:text-mb-text text-sm lg:text-base mb-6 max-w-70% lg:max-w-full mx-auto">
            You are about to scan your ID using your camera.{' '}
            <br className="lg:hidden" />
            Start scanning when you are ready and follow the on-screen
            instructions.
          </div>
          <p
            id="try-again"
            className="hidden text-center text-mb-red font-bold mb-4 max-w-70% lg:max-w-full mx-auto"
          >
            Scan unsuccessful, please try again
          </p>
          <input
            id="real-scan-button"
            data-jcs-element="camera__capture"
            className="hidden"
            type="button"
            value="Start scanning"
          />
          <input
            id="proxy-scan-button"
            className="block py-2 px-4 mx-auto bg-mb-green text-white font-bold cursor-pointer rounded hover:bg-mb-orange max-w-70% lg:max-w-full mx-auto disabled:bg-mb-gray-light font-bold"
            type="button"
            value="Start scanning"
          />
        </div>
        <div className="relative overflow-hidden lg:rounded lg:mt-2">
          <div
            id="success"
            className="absolute w-full h-full hidden flex flex-col justify-center items-center bg-mb-gray-bg-trans bg-blur z-50"
          >
            <img src={Processing} alt="" />
            <span className="loading text-center text-white font-bold text-xl mt-2">
              Processing
            </span>
          </div>
          <div className="flex justify-center">
            <div className="relative">
              <div className="absolute z-30 bottom-scanner sm:bottom-scanner-landscape w-full">
                <p
                  id="capture-instructions"
                  className="hidden lg:block text-center text-white font-bold max-w-70% bg-mb-gray-bg-trans-light bg-blur rounded p-2"
                >
                  {initialInstruction}
                </p>
              </div>
              <canvas
                data-jcs-element="camera__viewfinder"
                className="block mx-auto max-h-image max-w-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default SmartCaptureTemplate;
