import React, { useCallback, useState } from 'react';

import './assets/tailwindOutput.css';
import './assets/custom.css';
import './assets/gbg.css';

import MbLogoBlue from './assets/logos/mb-logo-blue.svg';

import Loading from './templates/Loading';
import useJourney from './hooks/useJourney';
import useIdleTimeout from './hooks/useIdleTimout';
import Popup, { PopupConfig } from './components/Popup';

const rootContainerId = 'gbg-root';

const App = () => {
  const [popupConfig, setPopupConfig] = useState<PopupConfig | undefined>();

  const showPopup = useCallback(
    () =>
      setPopupConfig({
        title: 'Session timeout',
        text: 'Sorry your session timed out and we had to restart your journey.',
        buttonText: 'Continue',
      }),
    [setPopupConfig]
  );
  useJourney(rootContainerId, showPopup);

  useIdleTimeout(setPopupConfig);

  return (
    <>
      <div className="font-body text-mb-text lg:bg-bottom lg:bg-no-repeat lg:pb-48">
        <div className="header-border hidden lg:block bg-white mb-4 py-4 lg:px-8 text-mb-gray-mid font-bold">
          <div className="container flex justify-between items-center mx-auto">
            <h1 className="max-w-mb-logo text-left w-full m-0">
              <img src={MbLogoBlue} alt="MyBuilder" />
            </h1>
            <p className="m-0">Id Verification</p>
          </div>
        </div>
        {popupConfig && (
          <Popup
            title={popupConfig.title}
            text={popupConfig.text}
            buttonText={popupConfig.buttonText}
            onButtonClick={() => setPopupConfig(undefined)}
          />
        )}
        <div id={rootContainerId} className="lg:max-w-screen-md mx-auto h-full">
          <Loading />
        </div>
      </div>
    </>
  );
};

export default App;
