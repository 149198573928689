import React from 'react';

import {
  FlowState,
  IJourneyEventMetaData,
  IJourneyState,
  JourneyEvent,
} from '../gbg-sdk/idscan-jcs';

import FaceMask from '../assets/overlays/liveness.png';
import FaceMaskSuccess from '../assets/overlays/liveness-success.png';

import Processing from '../assets/icons/processing.svg';
import SelfieIcon from '../assets/icons/selfie.svg';
// import SwitchCamera from '../assets/icons/flip-camera.svg';

import Header from './shared/Header';

const lookStraight = 'Look Straight';
const countdownTime = 3;

const getPoseText = (pose: string) => {
  switch (pose) {
    case lookStraight:
      return 'Look straight ahead';
    case 'Smile':
      return 'Please smile';
    case 'Frown':
      return 'Now frown';
    default:
      return pose;
  }
};

const toggleOpacity = (element: Element, opaque: boolean) => {
  if (opaque) {
    element.classList.remove('opacity-0');
    element.classList.add('opacity-100');
    return;
  }
  element.classList.remove('opacity-100');
  element.classList.add('opacity-0');
};

const poseSuccess = (poseText?: string) => {
  const background = document.querySelector('#success-overlay');
  const poseTextContainer = document.querySelector('#pose-text');
  if (background && poseTextContainer) {
    background.classList.remove('hidden');
    toggleOpacity(poseTextContainer, false);
    setTimeout(() => {
      toggleOpacity(background, false);
      setTimeout(() => {
        background.classList.add('hidden');
        toggleOpacity(background, true);

        if (poseText) {
          setTimeout(() => {
            if (poseTextContainer) {
              poseTextContainer.innerHTML = poseText;
              poseTextContainer.classList.remove('hidden');
              toggleOpacity(poseTextContainer, true);
            }
          }, 200);
        }
      }, 700);
    }, 300);
  }
};

const hideOverlay = () => {
  document.querySelector('#overlay')?.classList.add('hidden');
  document.querySelector('#start-capture-button')?.classList.remove('hidden');
};

const startCapture = () => {
  const poseText = document.querySelector('#pose-text');
  const actualButton = document.querySelector(
    '#actual-start-button'
  ) as HTMLButtonElement;
  if (actualButton) {
    actualButton.click();
  }
  if (poseText) {
    poseText.classList.remove('hidden');
    setTimeout(() => {
      if (poseText) {
        toggleOpacity(poseText, true);
      }
    }, 10);
  }
};

const startCamera = () => {
  document.querySelector('#start-capture-button')?.classList.add('hidden');
  document.querySelector('#start-capture-button')?.classList.add('lg:hidden');
  const alignText = document.querySelector('#align-text');
  if (alignText) {
    toggleOpacity(alignText, false);
  }
  const countdownContainer = document.querySelector('#countdown-container');
  const countdown = document.querySelector('#countdown-text');

  if (countdown && countdownContainer) {
    countdown.innerHTML = countdownTime.toString();
    countdownContainer.classList.remove('hidden');
    let current = countdownTime;
    const interval = setInterval(() => {
      current -= 1;
      if (current === 0) {
        clearInterval(interval);
        countdownContainer.classList.add('hidden');
        startCapture();
        return;
      }
      countdown.innerHTML = current.toString();
    }, 1000);
  } else {
    startCapture();
  }
};

export const livenessJs = (
  event: JourneyEvent,
  meta: IJourneyEventMetaData,
  state: IJourneyState
) => {
  switch (event) {
    case JourneyEvent.CAMERA_READY:
      if (state.action !== FlowState.LIVENESS) {
        break;
      }
      setTimeout(() => {
        document.querySelector('#liveness-loading')?.classList.add('hidden');
        document
          .querySelector('#liveness-container')
          ?.classList.remove('hidden');
        document
          .querySelector('#hide-overlay-button')
          ?.addEventListener('click', hideOverlay);
        document
          .querySelector('#start-capture-button')
          ?.addEventListener('click', startCamera);
      }, 1000);

      break;
    case JourneyEvent.CAMERA_POSE:
      if (meta.pose !== lookStraight) {
        poseSuccess(getPoseText(meta?.pose));
      }
      break;
    case JourneyEvent.LIVENESS_PROCESSING:
      poseSuccess();
      setTimeout(
        () => document.querySelector('#success')?.classList.remove('hidden'),
        1000
      );
      break;
    default:
      break;
  }
};

const LivenessTemplate = () => (
  <>
    <div id="liveness-loading" className="text-center pt-4 lg:pt-0 h-screen">
      <h2 className="text-lg text-mb-blue font-bold loading">Loading</h2>
      <p>Loading the camera</p>
    </div>
    <div id="liveness-container" className="hidden">
      <Header title="Selfie" />
      <select
        id="camera-select"
        data-jcs-element="camera__select"
        className="hidden"
      />
      <div className="relative">
        <div
          id="overlay"
          className="absolute lg:static lg:block h-full w-full lg:h-auto z-50 lg:z-auto bg-mb-gray-bg-trans bg-blur lg:bg-white lg:mt-2 lg:py-4 lg:px-6 lg:rounded text-center lg:text-left flex flex-col justify-center items-center lg:justify-start lg:items-start"
        >
          <img
            src={SelfieIcon}
            alt=""
            className="mb-4 w-icon-mobile lg:hidden"
          />
          <div className="inline-block lg:hidden text-white text-lg font-bold mb-2 max-w-70% lg:max-w-full mx-auto">
            Selfie time!
          </div>
          <div className="text-white lg:text-mb-text text-sm lg:text-base max-w-70% lg:max-w-full mx-auto">
            We need photos of you to help verify your ID.
            <br className="lg:hidden" />
            <br />
            <span className="hidden lg:inline">
              Align your head within the oval and once started follow the
              on-screen instructions.
            </span>
            <span className="lg:hidden">
              Open the camera when you&apos;re ready and follow the on-screen
              instructions.
            </span>
          </div>
          <button
            id="hide-overlay-button"
            className="mt-6 block mx-auto text-white font-display cursor-pointer rounded px-4 py-2 bg-mb-green hover:bg-mb-orange disabled:bg-mb-gray-light disabled:cursor-default lg:hidden"
            type="button"
          >
            Open camera
          </button>
        </div>
        <div className="relative flex justify-center items-center lg:my-2 overflow-hidden lg:rounded">
          <div
            id="success"
            className="absolute left-0 right-0 top-0 bottom-0 z-50 hidden flex flex-col justify-center items-center bg-mb-gray-bg-trans bg-blur lg:rounded"
          >
            <img src={Processing} alt="" />
            <span className="loading text-center text-white font-bold text-xl mt-2">
              Processing
            </span>
          </div>
          <img src={FaceMask} alt="" className="absolute h-full max-w-none" />
          <img
            id="success-overlay"
            src={FaceMaskSuccess}
            alt=""
            className="absolute h-full max-w-none z-20 hidden opacity-100 transition-all duration-1000 ease-in-out"
          />
          <div
            id="align-text"
            className="flex items-center justify-center mx-4 absolute left-0 right-0 top-0 text-white text-center font-bold opacity-100 transition-all duration-1000 ease-in-out"
          >
            Align your head within the oval
          </div>
          <div className="liveness-top absolute flex justify-center z-40">
            <div
              id="pose-text"
              className="text-3xl text-white font-bold hidden opacity-0 transition-all duration-1000 ease-in-out px-2 rounded"
            >
              {getPoseText(lookStraight)}
            </div>
          </div>
          <div
            id="countdown-container"
            className="hidden absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center z-40"
          >
            <h3
              id="countdown-text"
              className="
            text-white font-bold absolute"
            >
              {countdownTime}
            </h3>
          </div>

          <canvas
            data-jcs-element="camera__viewfinder"
            className="block mx-auto max-h-image object-contain w-full"
          />

          <button
            id="start-capture-button"
            type="button"
            className="absolute bottom-0 mb-16 lg:mb-12 hidden lg:block mx-auto text-white font-display cursor-pointer rounded px-4 py-2 bg-mb-green hover:bg-mb-orange disabled:bg-mb-gray-light disabled:cursor-default"
          >
            Start taking selfies
          </button>
          <input
            id="actual-start-button"
            type="button"
            data-jcs-element="retina__start"
            className="hidden"
          />
        </div>
      </div>
    </div>
  </>
);

export default LivenessTemplate;
