export type CameraFeedback = {
  alignmentCheck?: boolean;
  blurCheck?: boolean;
  glareCheck?: boolean;
  failed?: boolean;
};

export const getCommentary = ({
  alignmentCheck,
  blurCheck,
  glareCheck,
}: CameraFeedback) => {
  const instructions = [
    alignmentCheck && 'align {DOC} inside the box',
    blurCheck && 'make sure {DOC} is in focus',
    glareCheck && 'try to reduce glare',
  ].filter((x) => x);
  if (instructions.length) {
    const list = `${
      instructions.length === 1
        ? instructions[0]
        : `${instructions.slice(0, instructions.length - 1).join(', ')} and ${
            instructions[instructions.length - 1]
          }`
    }.`
      .replace('{DOC}', 'your ID')
      .replace('{DOC}', 'it');
    return list[0].toUpperCase() + list.slice(1);
  }
};
