import React from 'react';

const InitializingTemplate = () => (
  <div className="text-center pt-4 lg:pt-0 h-screen">
    <h2 className="text-lg text-mb-blue font-bold loading">Loading</h2>
    <p>Checking for camera</p>
  </div>
);

export default InitializingTemplate;
