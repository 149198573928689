import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import IdCheckContextProvider from './providers/IdCheckContextProvider';
import { ConfigProvider } from './providers/ConfigProvider';
import ErrorBoundary from './errors/ErrorBoundary';
import { RedirectProvider } from './providers/RedirectProvider';
import { installTrackJsIfNeeded } from './logging';
import HotjarProvider from './providers/HotjarProvider';

installTrackJsIfNeeded();

ReactDOM.render(
  <React.StrictMode>
    <RedirectProvider>
      <ErrorBoundary>
        <ConfigProvider>
          <IdCheckContextProvider>
            <HotjarProvider>
              <App />
            </HotjarProvider>
          </IdCheckContextProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </RedirectProvider>
  </React.StrictMode>,
  document.getElementById('react-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
