import React from 'react';

import MbLogoBlue from '../assets/logos/mb-logo-blue.svg';
import MbLogoLight from '../assets/logos/mb-logo-light.png';

type Props = { children: React.ReactNode };
type State = { hasError: boolean };

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="font-body text-mb-text lg:bg-bottom lg:bg-no-repeat lg:pb-48">
          <div className="header-border hidden lg:block bg-white mb-4 py-4 lg:px-8 text-mb-gray-mid font-bold">
            <div className="container flex justify-between items-center mx-auto">
              <h1 className="max-w-mb-logo text-left w-full m-0">
                <img src={MbLogoBlue} alt="MyBuilder" />
              </h1>
              <p className="m-0">Id Verification</p>
            </div>
          </div>
          <div className="lg:max-w-screen-md mx-auto h-full">
            <div className="relative flex justify-center p-4 bg-mb-blue border-mb-dark-blue border-solid border-b-2 lg:hidden">
              <img
                src={MbLogoLight}
                alt="MyBuilder"
                className="inline-block lg:hidden max-h-30px"
              />
            </div>
            <div className="text-center pt-4 lg:pt-0 h-screen lg:bg-transparent px-4">
              <h2 className="text-lg text-mb-blue font-bold mb-4">
                Oops, something went wrong!
              </h2>
              <span>
                An unexpected error occurred. Please refresh the page or click{' '}
                <span
                  className="text-mb-blue"
                  onClick={() => window.location.reload()}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      window.location.reload();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  here
                </span>
              </span>
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
