import React from 'react';
import { useIdCheckContext } from '../providers/IdCheckContextProvider';

const ResultTemplate = () => {
  const { idCheckThanksUrl } = useIdCheckContext();

  return (
    <div className="text-center pt-4 lg:pt-0 h-screen">
      <h2 className="text-lg text-mb-blue font-bold loading">Please wait</h2>
      <p>
        You should be redirected automatically, if not please click{' '}
        <a className="text-mb-blue" href={idCheckThanksUrl}>
          here
        </a>
      </p>
    </div>
  );
};

export default ResultTemplate;
