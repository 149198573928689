import React from 'react';

import MbLogoLight from '../../assets/logos/mb-logo-light.png';

import { useIdCheckContext } from '../../providers/IdCheckContextProvider';

const Header = ({
  title,
  rightIcon,
}: {
  title: string;
  rightIcon?: React.ReactElement;
}) => {
  const { idCheckStartUrl } = useIdCheckContext();

  return (
    <>
      <div className="relative flex justify-between p-4 bg-mb-blue border-mb-dark-blue border-solid border-b-2 lg:bg-white lg:rounded lg:border-b-0">
        <a
          href={idCheckStartUrl}
          className="flex items-center px-2 py-1 text-xs font-bold text-white rounded bg-mb-dark-blue border-mb-darker-blue border-solid border-b-2 lg:text-mb-blue lg:bg-white lg:border-b-0"
        >
          Cancel
        </a>
        <span className="hidden lg:inline-block text-xl font-display text-mb-heading">
          {title}
        </span>
        <img
          src={MbLogoLight}
          alt="MyBuilder"
          className="inline-block lg:hidden max-h-30px"
        />
        <span className="invisible">Cancel</span>
        {rightIcon && (
          <div className="absolute right-4 text-white lg:text-mb-blue fill-current max-w-switch-camera">
            {rightIcon}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
