import React from 'react';

const GatewayTemplate = () => (
  <div className="relative">
    <div
      data-jcs-element="provider__container"
      className="provider-container"
    />
    <div
      data-jcs-element="gateway__upload__toggle__label"
      id="switch-id-capture"
      className="hidden text-center h-12 lg:h-auto lg:py-4 text-white absolute lg:static z-50 lg:z-auto bottom-0 w-full lg:text-mb-text lg:bg-white lg:rounded lg:mt-2"
    >
      <label htmlFor="upload-toggle" className="cursor-pointer">
        <span>Or </span>
        <input
          name="upload-toggle"
          id="upload-toggle"
          data-jcs-element="gateway__upload__toggle"
          type="checkbox"
        />
        <div
          id="file-upload"
          className="inline-block underline text-mb-blue font-bold"
        >
          upload a photo of your ID
        </div>
        <div
          id="smart-capture"
          className="inline-block underline text-mb-blue font-bold"
        >
          scan your ID
        </div>
      </label>
    </div>
  </div>
);

export default GatewayTemplate;
