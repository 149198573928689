import React, { createContext, useContext, useMemo } from 'react';
import { v4 as uuidv4, validate } from 'uuid';

import { useConfig } from './ConfigProvider';
import { useRedirect } from './RedirectProvider';

const validOrigins = ['tp', 'ta'];

type IdCheckContextType = {
  idCheckToken: string;
  idCheckStartPage: string;
  idCheckStartUrl: string;
  idCheckThanksUrl: string;
};

export const IdCheckContext = createContext<IdCheckContextType>({
  idCheckToken: '',
  idCheckStartPage: '',
  idCheckStartUrl: '',
  idCheckThanksUrl: '',
});

export const useIdCheckContext = () => useContext(IdCheckContext);

const getOriginUrls = (origin: string, marketplaceUrl: string) => {
  if (origin === 'ta') {
    const snippet = 'tradesman-application';
    const idCheckStartPage = `/${snippet}/id-check`;
    return {
      idCheckStartPage,
      idCheckStartUrl: `${marketplaceUrl}${idCheckStartPage}`,
      idCheckThanksUrl: `${marketplaceUrl}/${snippet}/thanks`,
    };
  }
  if (origin === 'tp') {
    const snippet = 'myaccount';
    const idCheckStartPage = `/${snippet}/id-check`;
    const idCheckStartUrl = `${marketplaceUrl}${idCheckStartPage}`;
    return {
      idCheckStartPage,
      idCheckStartUrl,
      idCheckThanksUrl: `${idCheckStartUrl}/thanks`,
    };
  }
  throw new Error(`Origin ${origin} not recognised`);
};

const getContext = (
  idCheckToken: string,
  marketplaceUrl: string,
  origin: string
) => {
  return {
    idCheckToken,
    ...getOriginUrls(origin, marketplaceUrl),
  };
};

export const buildFromQueryString = (
  searchParams: string,
  marketplaceUrl: string,
  isProd: boolean,
  redirect: (url: string) => void
) => {
  const queryParams = new URLSearchParams(searchParams);
  const idCheckToken = queryParams.get('idCheckToken');
  const origin = queryParams.get('origin');

  const idCheckTokenValid = idCheckToken && validate(idCheckToken);
  const originValid = origin && validOrigins.includes(origin);

  if (!idCheckToken || !idCheckTokenValid || !origin || !originValid) {
    if (isProd) {
      redirect(marketplaceUrl);
      throw new Error(
        `?idCheckToken=${idCheckToken}&origin=${origin} is an invalid query string.`
      );
    }

    return getContext(idCheckToken || uuidv4(), marketplaceUrl, origin || 'tp');
  }

  return getContext(idCheckToken, marketplaceUrl, origin);
};

const IdCheckContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isProd, marketplaceUrl } = useConfig();
  const redirect = useRedirect();

  const context = useMemo(
    () =>
      buildFromQueryString(
        window.location.search,
        marketplaceUrl,
        isProd,
        redirect
      ),
    [isProd, marketplaceUrl, redirect]
  );

  return (
    <IdCheckContext.Provider value={context}>
      {children}
    </IdCheckContext.Provider>
  );
};

export default IdCheckContextProvider;
