import { useCallback, useEffect, useRef, useState } from 'react';
import { isAndroid, isIOS, osVersion } from 'react-device-detect';

import {
  IJourneyEventMetaData,
  IJourneyState,
  IMetaData,
  JourneyContainer,
  JourneyEvent,
} from '../gbg-sdk/idscan-jcs';

import { useConfig } from '../providers/ConfigProvider';
import { useRedirect } from '../providers/RedirectProvider';
import templates, { javascriptForProvider } from '../templates';
import { useIdCheckContext } from '../providers/IdCheckContextProvider';
import useTrackPage from './useTrackPage';
import useHandleError from './useHandleError';

export const journeys = {
  uploadAndLiveness: 'fcf95215-bac0-49c7-8749-6516c98df121',
  prodUploadAndLiveness: '6a8c2a01-19ac-4be0-80fb-4939c5ab06d6',
  cameraAndLiveness: 'e34018c4-85e7-499f-ad3e-800d82a152b3',
  prodCameraAndLiveness: '49da9a95-79d7-4ca3-b67d-b691855b956b',
  uploadOnly: 'ee6f8508-8459-487c-afd8-7b359ad40ac0',
  prodUploadOnly: 'db2415e2-0010-4dd7-a776-3315065d2059',
};

const getJourneyId = (
  isProd: boolean,
  hasCameraCapabilities: boolean
): string => {
  if (isProd) {
    return hasCameraCapabilities
      ? journeys.prodUploadAndLiveness
      : journeys.prodUploadOnly;
  }

  return hasCameraCapabilities
    ? journeys.uploadAndLiveness
    : journeys.uploadOnly;
};

const isAndroid11 = () => {
  if (!isAndroid) {
    return false;
  }
  return osVersion.split('.')[0] === '11';
};

const isIos16 = () => {
  if (!isIOS) {
    return false;
  }
  return osVersion.split('.')[0] === '16';
};

const getCameraCapabilities = (): Promise<boolean> => {
  if (isAndroid11() || isIOS) {
    return Promise.resolve(false);
  }
  if (!navigator?.mediaDevices?.getUserMedia) {
    return Promise.resolve(false);
  }
  return navigator.mediaDevices
    .getUserMedia({
      audio: false,
      video: {
        width: { min: 1280, ideal: 1440 },
        height: { min: 720, ideal: 900 },
      },
    })
    .then((stream) => {
      if (stream.getVideoTracks().length > 0) {
        return Promise.resolve(false);
      }
      return Promise.resolve(false);
    })
    .catch(() => Promise.resolve(false));
};

const useJourney = (containerId: string, onSessionTimeout: () => void) => {
  const { apiUrl, idScanUrl, isProd } = useConfig();
  const { idCheckToken, idCheckThanksUrl } = useIdCheckContext();
  const redirect = useRedirect();

  const trackPage = useTrackPage();
  const { handleError, hasPreviousError } = useHandleError();

  const journeyEnded = useRef(false);
  // If there was previously an error, we want to put them down the upload route as
  // this is less error prone.
  const [journey, setJourney] = useState<string | undefined>(() =>
    hasPreviousError ? getJourneyId(isProd, false) : undefined
  );
  const [error, setError] = useState<Error | undefined>();

  const fetchingToken = useRef(false);
  const [token, setToken] = useState<string>();
  const fetchToken = useCallback(() => {
    if (!fetchingToken || fetchingToken.current) {
      return;
    }
    fetchingToken.current = true;
    fetch(`${apiUrl}/access-token`, {
      method: 'POST',
      body: isProd
        ? JSON.stringify({
            idCheckToken,
          })
        : undefined,
    })
      .then(async (res) => {
        const message = `${res.status} ${res.statusText}`;

        const reject = (err: Error) => {
          // If it's a 400 response this means they have already completed the ID check so we redirect
          // them to the thanks page.
          if (res.status === 400) {
            redirect(idCheckThanksUrl);
          }
          return Promise.reject(err);
        };

        try {
          const json = await res.json();

          return res.ok ? json : reject(new Error(json?.message || message));
        } catch (err: any) {
          return reject(new Error(`${message} (${err.message})`));
        }
      })
      .then(({ accessToken }: { accessToken: string }) => {
        setToken(accessToken);
        if (fetchingToken && fetchingToken.current) {
          fetchingToken.current = false;
        }
      })
      .catch((err) => {
        setError(err);
      });
  }, [apiUrl, idCheckToken, idCheckThanksUrl, isProd, redirect]);

  const onJourneyEvent = useCallback(
    (
      event: JourneyEvent,
      meta: IJourneyEventMetaData,
      state: IJourneyState
    ) => {
      javascriptForProvider(event, meta, state);

      switch (event) {
        case JourneyEvent.JOURNEY_START:
          trackPage('start');
          break;
        case JourneyEvent.JOURNEY_PROGRESS:
          trackPage(
            `progress/${state.action.replace(':', '-').toLowerCase()}/attempt-${
              state.actionAttempt
            }`
          );
          break;
        case JourneyEvent.AUTH_CHANGE:
          if (!meta.authorized && !error) {
            onSessionTimeout();
            fetchToken();
          }
          break;
        case JourneyEvent.CAMERA_CAPABILITIES_UPDATED:
          setJourney(
            getJourneyId(
              isProd,
              !!Object.keys(meta?.capabilities?.cameras || {}).length
            )
          );
          break;
        case JourneyEvent.JOURNEY_END:
          trackPage('end');
          if (!journeyEnded.current) {
            journeyEnded.current = true;
            fetch(`${apiUrl}/id-check`, {
              method: 'POST',
              body: JSON.stringify({
                gbgIdCheckId: state.journey.journeyId,
                idCheckToken,
              }),
            });

            redirect(idCheckThanksUrl);
          }
          break;
        case JourneyEvent.ERROR:
          handleError(meta, state);
          break;
        default:
          break;
      }
    },
    [
      error,
      apiUrl,
      isProd,
      idCheckToken,
      idCheckThanksUrl,
      trackPage,
      onSessionTimeout,
      fetchToken,
      redirect,
      handleError,
    ]
  );

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    if (!journey) {
      getCameraCapabilities().then((hasCameraCapabilities) =>
        setJourney(getJourneyId(isProd, hasCameraCapabilities))
      );
    }
  }, [isProd, journey]);

  useEffect(() => {
    if (token && journey) {
      const jc = new JourneyContainer({
        backendUrl: idScanUrl,
        container: `#${containerId}`,
        auth: false,
        journeyDefinitionId: journey,
        token,
        templates,
        onJourneyEvent,
        smartCapture: {
          workerScriptUrl: '/gbg/ides-micro.a585b9f34a2f0475d118.js',
          asmScriptUrl: '/gbg/idesmicro_asm.js',
          timeout: 5000,
          recaptureDelay: 1000,
        },
        additionalData: [
          {
            name: 'IdCheckToken',
            value: idCheckToken,
          } as IMetaData,
          {
            name: 'UserName',
            value: 'MyBuilder', // The value of this doesn't matter but there was a bug in the SDK where it was providing UserName without a value and therefore threw a EXCEPTION_SERVER_DATA_NOT_PERSISTED error.
          } as IMetaData,
        ],
        overlay: {
          portraitReticle: { path: () => '' },
          documentReticle: { path: () => '' },
        },
        assetsDirectory: `${process.env.PUBLIC_URL}/gbg`,
      });

      jc.initialize();

      return () => {
        jc.terminateJourney();
        jc.terminate();
      };
    }
  }, [containerId, journey, token, idCheckToken, idScanUrl, onJourneyEvent]);

  // This will trigger us to fall back to the error boundary
  if (error) {
    throw error;
  }
};

export default useJourney;
