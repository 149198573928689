import React from 'react';

export type PopupConfig = {
  title: string;
  text: string | React.ReactElement;
  buttonText: string;
};

const Popup = ({
  title,
  text,
  buttonText,
  onButtonClick,
}: PopupConfig & {
  onButtonClick: () => void;
}) => {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-mb-gray-bg-trans bg-blur flex justify-center items-center">
      <div className="bg-white rounded text-center overflow-hidden pb-4 mx-4">
        <h2 className="bg-mb-blue text-white text-lg border-b border-solid border-mb-dark-blue py-2 px-8 m-0">
          {title}
        </h2>
        <div className="px-8 py-4">{text}</div>
        <button
          className="block mx-auto p-2 bg-mb-green hover:bg-mb-orange disabled:bg-mb-gray-light text-white rounded w-full max-w-button cursor-pointer"
          type="button"
          onClick={onButtonClick}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default Popup;
