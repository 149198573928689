import React, { useEffect } from 'react';
import { useIdle } from 'react-use';
import { PopupConfig } from '../components/Popup';
import { useIdCheckContext } from '../providers/IdCheckContextProvider';

// Show the popup after 30 minutes to allow them to stay on the page. After another minute, redirect.

const useIdleTimeout = (setPopupConfig: (config: PopupConfig) => void) => {
  const idleShowPopup = useIdle(1800e3);
  const idleRedirect = useIdle(1860e3);
  const { idCheckStartUrl } = useIdCheckContext();

  useEffect(() => {
    if (idleShowPopup) {
      setPopupConfig({
        title: 'Session timeout',
        text: (
          <>
            <div>
              You have been inactive for too long. We will shortly redirect you
              back to the start page.
            </div>
            <div className="pt-2">
              To stop this, continue your ID check now.
            </div>
          </>
        ),
        buttonText: 'Continue',
      });
    }
  }, [idleShowPopup, setPopupConfig]);

  useEffect(() => {
    if (idleRedirect) {
      window.location.href = idCheckStartUrl;
    }
  }, [idleRedirect, idCheckStartUrl]);
};

export default useIdleTimeout;
