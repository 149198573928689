import { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga';

import { useConfig } from '../providers/ConfigProvider';
import { useIdCheckContext } from '../providers/IdCheckContextProvider';

const useTrackPage = () => {
  const { isProd } = useConfig();
  const { idCheckStartPage } = useIdCheckContext();

  useEffect(() => {
    // The GA id will be eventually passed in an env variable
    ReactGA.initialize(isProd ? 'UA-273730-2' : 'UA-273730-9', {
      testMode: process?.env?.NODE_ENV === 'test',
    });
  }, [isProd]);

  return useCallback(
    (url: string) => ReactGA.pageview(`${idCheckStartPage}/${url}`),
    [idCheckStartPage]
  );
};

export default useTrackPage;
