import React from 'react';
import Header from '../shared/Header';

import Processing from '../../assets/icons/processing.svg';

import {
  FlowState,
  IJourneyEventMetaData,
  IJourneyState,
  JourneyEvent,
} from '../../gbg-sdk/idscan-jcs';

const setActionText = (
  header: string,
  description: string,
  removeButtonText?: string,
  continueButtonText?: string
) => {
  const headerContainer = document.querySelector('#review-header');
  const descriptionContainer = document.querySelector('#review-description');
  const removeButton = document.querySelector('#remove-file');
  const continueButton = document.querySelector(
    '#continue-button'
  ) as HTMLInputElement;
  if (headerContainer) {
    headerContainer.innerHTML = header;
  }
  if (descriptionContainer) {
    descriptionContainer.innerHTML = description;
  }
  if (removeButton && removeButtonText) {
    removeButton.innerHTML = removeButtonText;
  }
  if (continueButton && continueButtonText) {
    continueButton.value = continueButtonText;
  }
};

export const viewJs = (
  event: JourneyEvent,
  meta: IJourneyEventMetaData,
  state: IJourneyState
) => {
  switch (event) {
    case JourneyEvent.DISPLAY_PROVIDER:
      if (meta.provider === 'view') {
        const switchCaptureMethod =
          document.querySelector('#switch-id-capture');

        // Hack because the styling of this control is outside this template
        switchCaptureMethod?.classList.remove('text-white');

        switch (state.action) {
          case FlowState.IDENTITY_FRONT:
            setActionText(
              'Is everything readable on your ID?',
              'Look out for glare, blur, or missing details.'
            );
            break;
          case FlowState.IDENTITY_BACK:
            setActionText(
              'Is everything readable on your ID?',
              'Look out for glare, blur, or missing details.'
            );
            break;
          case FlowState.SELFIE:
            setActionText(
              'Does it look like you?',
              'Make sure you are looking straight into the camera and your face is clearly visible.',
              'No, let me try again',
              'Yes, looking good!'
            );
            // For the selfie GBG forget to hide this for us so we have to do it.
            switchCaptureMethod?.classList.add('hidden');
            break;
          default:
            break;
        }
      }
      break;
    case JourneyEvent.TRANSFER_STARTED:
      if (meta.name === 'upload') {
        document.querySelector('#success')?.classList.remove('hidden');
      }
      break;
    case JourneyEvent.TRANSFER_FAILED:
      if (meta.name === 'upload') {
        setActionText(
          "We couldn't use that photo",
          'Something went wrong uploading that photo, please try another one.',
          'Upload another photo'
        );
        document.querySelector('#success')?.classList.add('hidden');
        document.querySelector('#continue-button')?.classList.add('hidden');
        document.querySelector('#preview-image')?.classList.add('hidden');
        const label = document.querySelector('#file-preview');
        if (label) {
          label.className = 'flex justify-center';
        }
      }
      break;
    default:
      break;
  }
};

const ViewTemplate = () => (
  <>
    <div className="h-full lg:h-auto">
      <Header title="ID Check" />
      <div className="relative lg:static px-6 lg:px-0">
        <div className="py-6 lg:py-4 lg:px-6 lg:bg-white lg:mt-2 lg:rounded">
          <div
            id="review-header"
            className="inline-block text-mb-heading font-bold text-xl lg:text-lg"
          >
            Check your photo
          </div>
          <div id="review-description" className="mt-2 text-sm lg:text-base">
            Please make sure that everything is in focus in the photo and there
            is minimal glare.
          </div>
        </div>
        <div className=" pb-36 lg:p-4 lg:pb-2 lg:bg-white lg:rounded-t lg:mt-2">
          <label
            id="file-preview"
            className="flex flex-col justify-between items-center p-4 lg:py-8 lg:px-4 min-h-file-upload max-h-file-upload cursor-pointer bg-mb-blue-lightest border-dashed border-mb-gray-light border-2 rounded text-center text-mb-gray-mid lg:relative"
            htmlFor="view__retry"
          >
            <div
              id="success"
              className="absolute left-0 right-0 top-0 bottom-0 h-full z-50 hidden flex flex-col justify-center items-center bg-mb-gray-bg-trans bg-blur lg:rounded"
            >
              <img src={Processing} alt="" />
              <span className="loading text-center text-white font-bold text-xl mt-2">
                Processing
              </span>
            </div>
            <input
              id="view__retry"
              data-jcs-element="view__retry"
              className="hidden"
              type="button"
              value="Choose another"
            />
            <img
              // This suppresses the images of the users ID from hotjar. Do not remove.
              data-hj-suppress=""
              id="preview-image"
              alt=""
              data-jcs-element="view__preview"
              className="block my-2 mx-auto max-h-image object-contain overflow-hidden w-full"
            />
            <span
              id="remove-file"
              className="block bg-mb-red rounded mt-2 p-2 text-white font-display cursor-pointer"
            >
              No, try again
            </span>
          </label>
        </div>
        <div className="absolute left-0 right-0 bottom-18 lg:static pt-4 lg:p-4 lg:pt-2 lg:bg-white lg:rounded-b">
          <input
            id="continue-button"
            data-jcs-element="view__upload"
            className="block mx-auto p-2 bg-mb-green hover:bg-mb-orange disabled:bg-mb-gray-light text-white rounded w-full max-w-button cursor-pointer font-display"
            type="button"
            value="Yes, continue"
          />
        </div>
      </div>
    </div>
  </>
);

export default ViewTemplate;
