import React, { createContext, useCallback, useContext } from 'react';

type Redirect = (url: string) => void;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const RedirectContext = createContext<Redirect>(() => {});

export const useRedirect = () => useContext(RedirectContext);

export const RedirectProvider: React.FC = ({ children }) => {
  const redirect = useCallback(
    (url: string) => window.location.assign(url),
    []
  );

  return (
    <RedirectContext.Provider value={redirect}>
      {children}
    </RedirectContext.Provider>
  );
};
