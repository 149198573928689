import React, { createContext, useContext, useMemo } from 'react';

type Config = {
  apiUrl: string;
  idScanUrl: string;
  marketplaceUrl: string;
  isProd: boolean;
  stage: string;
};

export const ConfigContext = createContext<Config>({
  apiUrl: '',
  idScanUrl: '',
  marketplaceUrl: '',
  isProd: false,
  stage: '',
});

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider: React.FC = ({ children }) => {
  const config = useMemo(
    () => ({
      apiUrl: process.env?.REACT_APP_API_URI ?? '',
      idScanUrl: process.env?.REACT_APP_GBG_ID_SCAN_URL ?? '',
      marketplaceUrl: process.env?.REACT_APP_MARKETPLACE_URL ?? '',
      isProd: process.env?.REACT_APP_STAGE === 'prod',
      stage: process.env?.REACT_APP_STAGE ?? '',
    }),
    []
  );

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
